import { addSplideClasses, connectSplideArrows, sel, selAll } from './utils'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { Intersection } from '@splidejs/splide-extension-intersection'
import { mapStyle } from './mapStyle'
import { Loader } from '@googlemaps/js-api-loader'

export default function Location() {
  function heroInit() {
    const name = 'location-hero'
    addSplideClasses(name + '__slider')
    const splide = new Splide('.' + name + '__slider', {
      // perPage: 1,
      // width: '100%',
      autoplay: false,
      // autoHeight: true,
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
    })

    addSplideClasses(name + '__slider-thumb')
    const thumbnails = new Splide('.' + name + '__slider-thumb', {
      gap: '1.5rem',
      perPage: 5,
      rewind: true,
      pagination: false,
      arrows: false,
      isNavigation: true,
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      breakpoints: {
        991: {
          // perPage: 4,
          // gap: '1rem',
        },
        768: {
          perPage: 4,
        },
        478: {
          perPage: 3,
          gap: '0.5rem',
        },
      },
    })
    splide.sync(thumbnails)
    splide.mount()
    thumbnails.mount()
    connectSplideArrows(splide, 'arrows', '.round-arrow')
  }
  heroInit()
  function locationInit() {
    selAll('.visiting-location__slider').forEach((slider, index) => {
      const speed = 3000
      const ease = 'cubic-bezier(0.1, 0, 0, 1)'
      const slide$a = slider.querySelectorAll('.w-dyn-item')
      slide$a.forEach((slide) => {
        const card$ = slide.querySelector('.visiting-location__restaurants__item')
        const h$ = slide.querySelector('.visiting-location__card__h')
        const p$ = slide.querySelector('.visiting-location__card__p')
        const data = slide
          .querySelector('img')
          .alt.split(';')
          .map((item) => item.trim())

        const [h, p, href] = data
        h$.textContent = h
        p$.textContent = p
        if (href) card$.href = href
      })

      const slideNum = parseFloat(slider.dataset.slides) // Convert string to number
      let widthParams = {
        perPage: slideNum,
        perMove: slideNum,
      }
      if (!Number.isInteger(slideNum)) {
        const slideWidth = Math.floor(slider.getBoundingClientRect().width / slideNum)
        // slider.querySelectorAll('.w-dyn-item').forEach((slide) => {
        //   slide.style.width = slideWidth + 'px'
        // })
        widthParams = {
          // autoWidth: true,
          fixedWidth: slideWidth + 'px',
        }
      }
      addSplideClasses(slider)
      const splide = new Splide(slider, {
        ...widthParams,
        gap: '1.5rem',
        rewind: true,
        pagination: false,
        arrows: false,
        autoplay: true,
        type: 'loop',
        speed: speed,
        easing: ease,
        interval: speed * 2,
        breakpoints: {
          991: {
            perPage: 2,
            perMove: 2,
            fixedWidth: false,
            gap: '1rem',
          },
          768: {
            perPage: 1,
            perMove: 1,
            speed: speed / 2,
          },
        },
      })

      const progress$ = slider.querySelector('.visiting-location__progress__bar')
      progress$.style.transition = `width ${speed / 1000}s ${ease}`
      splide.on('mounted move', function () {
        const end = splide.Components.Controller.getEnd() + 1
        const rate = Math.min((splide.index + 1) / end, 1)
        progress$.style.width = String(100 * rate) + '%'
      })
      splide.mount()
    })
    // const name = 'location-hero'
    // addSplideClasses(name + '__slider')
    // const splide = new Splide('.' + name + '__slider', {
    //   // perPage: 1,
    //   // width: '100%',
    //   autoplay: false,
    //   // autoHeight: true,
    //   type: 'fade',
    //   rewind: true,
    //   pagination: false,
    //   arrows: false,
    // })
  }
  locationInit()

  // Assuming the Google Maps API script is already loaded and available

  // function initMap() {
  //   // Target the element where you want to display the map
  //   const mapElement = document.getElementById('map$');

  //   // Define the options for the map
  //   const mapOptions = {
  //     zoom: 8, // Set the initial zoom level
  //     center: { lat: -34.397, lng: 150.644 }, // Set the initial center point of the map
  //     styles: mapStyle // Apply the custom styles from mapStyle
  //   };

  //   // Create a new map instance
  //   const map = new google.maps.Map(mapElement, mapOptions);
  // }

  // // Call initMap to initialize the map
  // initMap();

  // ----------------------------
  // Initialize and add the map
  // let map

  // async function initMap() {
  //   // The location of Uluru
  //   const position = { lat: -25.344, lng: 131.031 }
  //   // Request needed libraries.
  //   //@ts-ignore
  //   const { Map } = await google.maps.importLibrary('maps')
  //   const { AdvancedMarkerElement } = await google.maps.importLibrary('marker')

  //   // The map, centered at Uluru
  //   map = new Map(document.getElementById('map'), {
  //     zoom: 4,
  //     center: position,
  //     mapId: 'DEMO_MAP_ID',
  //   })

  //   // The marker, positioned at Uluru
  //   const marker = new AdvancedMarkerElement({
  //     map: map,
  //     position: position,
  //     title: 'Uluru',
  //   })
  // }

  // initMap()
  //
  // ----------------------------
  function mapInit() {
    const map$ = document.getElementById('locationMap')
    const location = map$.dataset.location
    const loader = new Loader({
      apiKey: 'AIzaSyDa3TkqwLNWecMQTydf8_7FRMyeIO5gP3M',
      version: 'weekly',
      libraries: ['places'],
    })

    const mapOptions = {
      center: {
        lat: 0,
        lng: 0,
      },
      zoom: 16,
      styles: mapStyle, // Apply the custom styles from mapStyle
    }
    // Promise for a specific library
    loader
      .importLibrary('maps')
      .then(({ Map }) => {
        const map = new Map(map$, mapOptions)
        // const pinBackground = new PinElement({
        //   background: '#FBBC04',
        // })
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode(
          {
            address: location,
          },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              new google.maps.Marker({
                position: results[0].geometry.location,
                map: map,
                icon: pinSymbol(),
              })
              map.setCenter(results[0].geometry.location)
            }
          }
        )
      })
      .catch((e) => {
        // do something
      })
    function pinSymbol() {
      return {
        path: 'M24.5941 65.3144C24.7441 65.4492 24.8796 65.5698 24.9996 65.6758C25.1197 65.5696 25.2554 65.4487 25.4055 65.3137C26.0799 64.7073 27.0461 63.8154 28.2067 62.6792C30.5293 60.4058 33.6245 57.1602 36.7174 53.2716C42.9381 45.45 49 35.2105 49 25.125C49 18.725 46.4702 12.5882 41.9688 8.06428C37.4675 3.54052 31.3636 1 25 1C18.6364 1 12.5325 3.54052 8.0312 8.06428C3.52977 12.5882 1 18.725 1 25.125C1 35.224 7.06201 45.4636 13.2825 53.2814C16.3753 57.1683 19.4704 60.4113 21.7929 62.6827C22.9536 63.8178 23.9197 64.7087 24.5941 65.3144ZM32.6937 36.6886C30.4168 38.2176 27.7394 39.034 25 39.034C23.1812 39.034 21.3803 38.674 19.7002 37.9746C18.0201 37.2752 16.4939 36.2502 15.2085 34.9584C13.9231 33.6666 12.9038 32.1333 12.2084 30.4461C11.513 28.759 11.1552 26.9509 11.1552 25.125C11.1552 22.3752 11.9666 19.6867 13.4872 17.3995C15.0079 15.1122 17.1698 13.3288 19.7002 12.2754C22.2306 11.2221 25.0153 10.9464 27.7019 11.4834C30.3885 12.0205 32.8556 13.346 34.7915 15.2916C36.7273 17.2371 38.0451 19.7153 38.5789 22.4124C39.1128 25.1095 38.8388 27.9052 37.7916 30.4461C36.7443 32.9871 34.9705 35.1597 32.6937 36.6886Z',
        fillColor: '#00E5E5',
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 1,
        anchor: new google.maps.Point(25, 67),
      }
    }
  }
  mapInit()
}
