import Splide from '@splidejs/splide'
import { addSplideClasses, connectSplideArrows, sel, selAll } from './utils'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

export default function OldHome() {
  function heroInit() {
    const name = 'location-hero'
    addSplideClasses('.therapeutics__img-slider')
    const splide = new Splide('.therapeutics__img-slider', {
      // perPage: 1,
      // width: '100%',
      autoplay: false,
      // autoHeight: true,
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
    })

    addSplideClasses('.therapeutics__info-slider')
    const cards = new Splide('.therapeutics__info-slider', {
      gap: '1.5rem',
      // perPage: 5,
      // rewind: true,
      pagination: false,
      arrows: false,
      // isNavigation: true,
      // type: 'loop',
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    })
    splide.sync(cards)
    splide.mount()
    cards.mount()
    connectSplideArrows(splide, '.therapeutics__arrows', '.therapeutics__arrow')
    const numCurrent$ = sel('.therapeutics__pagination__current')
    const numTotal$ = sel('.therapeutics__pagination__total')
    splide.on('moved', () => {
      numCurrent$.textContent = `0${splide.index + 1}`
    })
    numCurrent$.textContent = `0${splide.index + 1}`
    numTotal$.textContent = '0' + splide.length
  }
  heroInit()

  // Diagnostics sec
  const diagnostics$ = sel('.diagnostics__lo')
  const diagnosticsImg$ = sel('.diagnostics__img')
  const diagnosticsImgBg$ = sel('.diagnostics__vid__bg-img')
  const diagnosticsImgFg$ = sel('.diagnostics__vid__fg-img')
  const diagnosticsDim$ = sel('.diagnostics__vid__dim')
  const diagnosticsVidW$ = sel('.diagnostics__vid-w')
  const diagnosticsInfo$a = selAll('.diagnostics__info')
  // diagnosticsImgBg$.style.opacity = '0'
  const diagnosticsTl = gsap
    .timeline({ defaults: { ease: 'power4.out', duration: 1 }, repeat: -1, repeatDelay: 2 })
    .to(diagnosticsImg$, { opacity: 0, duration: 2 })
    .to(diagnosticsDim$, { opacity: 0, duration: 2 }, 0)
  console.log('diagnosticsInfo$a', diagnosticsInfo$a)

  diagnosticsInfo$a.forEach((el, i) => {
    const currentTitle$ = el.querySelector('.diagnostics__percent-w')
    const currentText$ = el.querySelector('.diagnostics__p')
    const previousI = i > 0 ? i - 1 : diagnosticsInfo$a.length - 1
    const previousEl$ = diagnosticsInfo$a[previousI]
    const previousTitle$ = previousEl$.querySelector('.diagnostics__percent-w')
    const previousText$ = previousEl$.querySelector('.diagnostics__p')
    const yShift = 20
    gsap.set(currentTitle$, { opacity: 0, y: yShift })
    gsap.set(currentText$, { opacity: 0, y: yShift * 2 })

    if (i === 0) {
      diagnosticsTl.to(currentTitle$, { opacity: 1, y: 0, duration: 2 }, '>-1')
    } else {
      diagnosticsTl
        //
        .to(previousTitle$, { opacity: 0, y: -yShift, ease: 'power4.in', duration: 2 })
        .to(previousText$, { opacity: 0, y: -yShift, ease: 'power4.in', duration: 2 }, '>-1.8')
        .to(currentTitle$, { opacity: 1, y: 0, duration: 2 })
    }
    diagnosticsTl.to(currentText$, { opacity: 1, y: 0, duration: 2 }, '>-1.9')
  })
  diagnosticsTl
    //
    .to(diagnosticsDim$, { opacity: 1, duration: 2 })
    .to(diagnosticsImg$, { opacity: 1, duration: 2 }, '<')
  // .from(diagnosticsImgBg$, { opacity: 0, duration: 1 }, '<')

  ScrollTrigger.create({
    animation: diagnosticsTl,
    trigger: diagnostics$,
    start: 'top 50%',
  })
}
