setTimeout(() => {
  // document.querySelectorAll('video').forEach((video) => video.pause())
}, 1000)

import './style.styl'
import { sel, selAll, onDomReady, addSplideClasses, connectSplideArrows, connectSplideBullets } from './utils'
import Home from './home'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import Location from './location'
import Lp from './lp'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import OldHome from './old-home'

const lenis = new Lenis()
lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0)

const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'location':
    Location()
    break
  case 'lp':
    Lp()
    break
  case 'old-home':
    OldHome()
    break
  case 'error':
    Error()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

// Service cards
const overviewList$ = sel('.lp-overview__list')
if (overviewList$) {
  overviewList$.classList.add('is--closed')
  const overviewBtn$ = sel('#overview-btn')
  // if (!overviewBtn$) return console.error('overviewBtn$ not found')

  const overviewBtnText$ = overviewBtn$.querySelector('.accordion__a__p')
  overviewBtn$.onclick = () => {
    if (overviewList$.classList.contains('is--closed')) {
      overviewBtnText$.textContent = '+ View Less Diagnostics'
    } else {
      overviewBtnText$.textContent = '+ View More Diagnostics'
    }
    overviewList$.classList.toggle('is--closed')
  }
}

// Faq chat link
const chatLink$ = document.querySelector('#faq-chat')

if (chatLink$) {
  chatLink$.onclick = () => {
    LO.messenger.open()
    console.log('chat clicked')
  }
}

// FAQ Accordion
onDomReady(() => {
  const faqDd = selAll('.faq__accordion')
  const shiftX = -1000

  faqDd.forEach((faq, i) => {
    const faqTitle$ = faq.querySelector('.faq__question-w')
    const faqDdIco$ = faq.querySelector('.faq__question__ico-w')
    const faqP$ = faq.querySelector('.faq__answer__p')
    const faqNav$a = faq.querySelectorAll('.faq__answer__li')
    const faqBg$ = faq.querySelector('.faq__bg-w')

    // console.log(faq)
    const faqLi$a = faq.querySelectorAll('.faq__answer__li-w')
    faqLi$a.forEach((faqLi, i) => {
      const nav = faqLi.querySelector('.faq__answer__li')
      const modalW$ = faqLi.querySelector('.faq__item__mod-w')
      const modalClose$ = faqLi.querySelector('.faq__item__x-w')
      const modalCloseBg$ = faqLi.querySelector('.faq__item__mod-bg')
      const faqTl = gsap.timeline({
        defaults: {
          duration: 1.2,
          ease: 'none',
        },
        paused: true,
      })

      faqTl
        .to(faqDdIco$, { opacity: 0, duration: 0.3 }, 0)
        .to([faqTitle$, faqP$, faqNav$a], { x: shiftX, opacity: 0 }, 0)
        .to(faqBg$, { x: shiftX / 2 }, 0)
        .set(modalW$, { display: 'block' }, 0)
        .fromTo(modalW$, { autoAlpha: 0 }, { autoAlpha: 1 }, 0)
        .from(modalW$, { x: 200 }, 0)

      nav.onclick = () => {
        //   faqTl.play()
        gsap.to(faqTl, { time: faqTl.duration(), duration: faqTl.duration(), ease: 'power4.out' })
      }
      ;[modalClose$, modalCloseBg$].forEach((el) => {
        el.onclick = () => {
          // faqTl.reverse()
          gsap.to(faqTl, { time: 0, duration: faqTl.duration(), ease: 'power4.out', overwrite: true })
          // gsap.set(modalW$, { display: 'none' }, 0)
        }
      })
    })
  })
})

// remove play buttons in the power saving mode
const vids$a = selAll('video')

vids$a.forEach((video) => {
  const promise = video.play()
  promise
    ?.catch(() => {
      const img$ = video.style.backgroundImage
      const parent$ = video.parentElement
      video.style.display = 'none'
      parent$.style.backgroundImage = img$
      parent$.style.backgroundPosition = 'center'
      parent$.style.backgroundSize = 'cover'
    })
    .then(() => {
      video.play()
    })
})

function storyMainSplide() {
  const prefix = 'stories'
  const el = '.' + prefix + '__slider'
  if (!sel(el)) return
  addSplideClasses(el)

  const slider = new Splide(el, {
    arrows: false,
    pagination: false,
    type: 'fade',
    gap: '0px',
    perPage: 1,
    // loop: true,
    rewind: true,
    speed: 1500,
    autoplay: 'true',
    // easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
  }).mount()
  connectSplideArrows(slider, prefix + '__arrows', '.round-arrow')
  connectSplideBullets(slider, prefix + '__bullets')
}
storyMainSplide()

function storyMoreSplide() {
  const prefix = 'stories-more'
  const el = sel('.' + prefix + '__slider')
  if (!el) return
  const list$ = el.querySelector('.w-dyn-items')
  const item$a = list$.querySelectorAll('.w-dyn-item')
  let div = document.createElement('div')
  div.classList.add('stories__mod-w')
  item$a.forEach((item, i) => {
    const modal$ = item.querySelector('.mod-w')
    // modal.querySelector('.mod__info').setAttribute('data-modal', '')
    const modalX$ = modal$.querySelector('.mod__x-w')
    const modalBg$ = modal$.querySelector('.mod-bg')
    // console.log(modalX$, modalBg$)

    div.appendChild(modal$)
    const modalTl = gsap.timeline({
      defaults: {
        duration: 1.2,
        ease: 'none',
      },
      paused: true,
    })
    modalTl.set(modal$, { display: 'block' }, 0).fromTo(modal$, { autoAlpha: 0, x: '5rem' }, { autoAlpha: 1, x: '0' }, 0)

    item.onclick = () => {
      console.log('clicked', i)
      gsap.to(modalTl, { time: modalTl.duration(), duration: modalTl.duration(), ease: 'power4.out' })
    }
    ;[modalX$, modalBg$].forEach((el) => {
      el.onclick = () => {
        gsap.to(modalTl, { time: 0, duration: modalTl.duration(), ease: 'power4.out', overwrite: true })
      }
    })
  })

  const storiesSec = sel('.stories-sec') || sel('.old-stories-sec')
  storiesSec.appendChild(div)

  addSplideClasses(el)

  const slider = new Splide(el, {
    arrows: false,
    pagination: false,
    gap: '1.25rem',
    perPage: 2,
    type: 'loop',
    autoplay: 'pause',
    /* intersection: {
        inView: {
          autoplay: true,
        },
        outView: {
          autoplay: false,
        },
      },*/
    speed: 1500,
    easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    autoScroll: { speed: 1, autoStart: true },
    breakpoints: {
      768: {
        perPage: 1,
      },
    },
  }).mount({ AutoScroll })
}
storyMoreSplide()

// Plans services
const plan$ = sel('#plan-mob')
if (plan$) {
  const serviceA$ = selAll('.membership__service-w')
  serviceA$.forEach((service) => {
    const toggle$ = service.querySelector('.imaging-dropdown-toggle')
    const plusIco$ = service.querySelector('.dropdown-plus-icon')
    const minusIco$ = service.querySelector('.dropdown-minus-icon')
    const list$ = service.querySelector('.membership__service__list')
    list$.classList.add('toggle--closed')
    toggle$.onclick = () => {
      list$.classList.toggle('toggle--closed')
      const closed = list$.classList.contains('toggle--closed')

      plusIco$.style.display = closed ? 'block' : 'none'
      minusIco$.style.display = closed ? 'none' : 'block'
    }
  })
}
